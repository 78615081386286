@tailwind base;
@tailwind components;
@tailwind utilities;

/** Copy over tailwind colors **/
:root {
    --color-primary: #efeee0;
    --color-secondary: #f0f;
    --color-tertiary: #0ff;

    --color-background: hsl(240, 2%, 12%);
}

body {
    background: var(--color-background);
    color: var(--color-primary);
    font-family: 'Lato', cursive;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 1.4rem;
    margin: 0;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Permanent Marker', cursive;
}

img {
    border: 1px solid var(--color-tertiary);
}

a {
    text-decoration: underline;
}


.about ul {
    margin-left: 2.4rem;
    margin-bottom: 1.2rem;
}

.about ul li {
    list-style-type: circle;
}

dl {
    margin-left: 2.4rem;
}

dl dd {
    margin-left: 1.2rem;
}

/** Scrollbar **/
body::-webkit-scrollbar {
    width: 1em;
}

body::-webkit-scrollbar-track {
   background: var(--color-background);
}

body::-webkit-scrollbar-thumb {
    background-color: var(--color-tertiary);
    outline: 1px solid slategrey;
}

/* Fonts */
.accent-font {
    color: var(--color-secondary);
    font-family: 'Permanent Marker', cursive;
}

.glow-primary {
    text-align: center;
    text-shadow:
            0 0 2px var(--color-primary),
            0 0 6px #a6a6a6,
            0 0 8px #727171,
            0 0 12px #727171;
}

.glow-secondary {
    text-align: center;
    text-shadow:
            0 0 2px var(--color-secondary),
            0 0 4px var(--color-secondary);
}

.glow-tertiary {
    color: var(--color-primary);
    text-align: center;
    text-shadow:
            0 0 2px rgba(239, 238, 224,0.92),
            0 0 8px rgba(239, 238, 224,0.34),
            0 0 3px rgba(30,132,242,0.52),
            0 0 6px rgba(30,132,242,0.92),
            0 0 8px rgba(30,132,242,0.78),
            0 0 10px rgba(30,132,242,0.92);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--color-tertiary);
}


/** Logo **/
.logo {
    font-size: 10px;
    margin: 0 auto 1.2em;
    min-height: 8em;
    width: 24em;
}

@media(min-width: 720px) {
    .logo {
        font-size: 14px;
    }
}

@media(min-width: 960px) {
    .logo {
        font-size: 20px;
    }
}

.logo-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.logo a {
    text-decoration: none;
}

.logo .tag {
    text-align: right;
    font-size: 1.35em;
    font-family: 'Permanent Marker', cursive;
    color: var(--color-secondary);
    padding-right: .35em;
}

.logo .logo-name {
    font-family: 'Work Sans', sans-serif;
    font-size: 5em;
    text-align: center;
    line-height: .8em;

    background: -webkit-radial-gradient(#ffffff, var(--color-primary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.logo .motto {
    font-size: 1.35em;
    font-weight: 300;
    letter-spacing: .3em;
    text-align: center;
    padding-top: .2em;
    text-indent: .3em;
}

.logo .triangle-bg {
    left: 4em;
    position: absolute;
    top: 7.6em;
    z-index: 1;
}

.logo .triangle {
    color: var(--color-background);
    font-size: 14em;
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-65deg) scale(.85, 1.23);
    width: 0;
    z-index: 1;
}


.logo .lines {
    background: repeating-linear-gradient(
        45deg,
        var(--color-background),
        var(--color-background) 6px,
        transparent 6px,
        transparent 12px
    );
    left: 0;
    min-height: 12em;
    position: absolute;
    top: -10em;
    width: 12em;
    z-index: 2;
}

.menus {
    position: relative;
    z-index: 10;
}

.nav-main .nav {
    background: var(--color-background);
    border: 1px solid var(--color-tertiary);
    display: none;
    margin-top: .6rem;

}

.nav-main .nav li a {
    display: block;
    text-decoration: none;
}

.nav-main .nav li:hover {
    background: var(--color-tertiary);
    color: white;
}

.nav-main .nav li:hover a {
    color: black;
}

.nav-main .nav.show-menu {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    display: flex;
}

.burger {
    width: 60px;
}

.burger span {
    background: var(--color-tertiary);
    border-radius:10px;
    display: block;
    height:7px;
    margin: 7px 0;
    transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.burger span:nth-of-type(1){
    width:50%;
}

.burger span:nth-of-type(2){
    width:100%;
}

.burger span:nth-of-type(3){
    width:75%;
}


.burger span.closed:nth-of-type(1){
    transform: translate(4px, 3px) rotatez(45deg);
    transform-origin:bottom;
}

.burger span.closed:nth-of-type(2){
    transform:rotatez(-45deg);
    transform-origin:top;
}

.burger span.closed:nth-of-type(3){
    transform: translate(21px,-8px) rotatez(45deg);
    transform-origin:bottom;
    width:50%;
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@media(min-width: 768px) {
    .nav-main .nav {
        background: none;
        display: flex;

    }
    .nav-main .burger {
        display: none;
    }
}


/** Layout Styles **/
.container {
    background: var(--color-background);
    box-shadow: 0 0 20px 5px #fff,
        0 0 33px 8px var(--color-secondary),
        0 0 42px 11px var(--color-tertiary);
}

.box-glow {
    background: var(--color-background);
    box-shadow: 0 0 1px 1px #fff,
        0 0 3px 3px var(--color-secondary),
        0 0 5px 5px var(--color-tertiary);
}

.grid-footer {
    background-color: transparent;
    background-image: linear-gradient(0deg, transparent 24%, var(--color-secondary) 25%, var(--color-secondary) 26%, transparent 27%, transparent 74%, var(--color-secondary) 75%, var(--color-secondary) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, var(--color-secondary) 25%, var(--color-secondary) 26%, transparent 27%, transparent 74%, var(--color-secondary) 75%, var(--color-secondary) 76%, transparent 77%, transparent);
    background-size: 60px 60px;
    border: 1px solid var(--color-secondary);
    border-bottom: none;
    bottom: 0;
    left: 0;
    min-height: 200px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    transform: rotateX(180deg);
}

.grid-footer-gradient {
    background: -moz-linear-gradient(top, transparent 0%, var(--color-background) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, var(--color-background))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, transparent 0%, var(--color-background) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, transparent 0%, var(--color-background) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, transparent 0%, var(--color-background) 100%); /* IE10+ */
    background: linear-gradient(to bottom, transparent 0%, var(--color-background) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0089fff1', endColorstr='#000000', GradientType=0); /* IE6-9 */
    bottom: 0;
    height: 280px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.grid-footer-top {
    background: radial-gradient(circle at center, white 0, white 5%, var(--color-secondary) 100%);
    height: 14px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
}

.footer-content {
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 3;
}

.footer {
    min-height: 300px;
}

/* Basic spinning loader */
.loader {
    animation: spin 2s linear infinite;
    border: 6px solid #d7d7d7;
    border-radius: 50%;
    border-top: 6px solid var(--color-tertiary);
    height: 35px;
    margin: 0 auto;
    width: 35px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/** Misc **/
.double-border-light {
    border-bottom: 2px solid var(--color-secondary);
    position: relative;
}

.double-border-light:before {
    content: " ";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 5px;
    right: 5px;
    bottom: -2px;
    border-bottom: 1px solid var(--color-primary);
}

.stars {
    background-color: var(--color-background);
    background-image:
            radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
            radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
            radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px),
            radial-gradient(white, rgba(255,255,255,.125) 1px, transparent 20px),
            radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 30px);
    background-size: 550px 500px, 350px 350px, 290px 300px, 175px 170px, 150px 150px;
    background-position: 0 0, 30px 60px, 130px 270px, 70px 100px, 80px 60px;
}

/** Forms **/
label {
    font-weight: bold;
    padding-bottom: .2rem;
}
input, select {
    background: var(--color-background);
    color: var(--color-primary);
    border-radius: 2px;
    border: 1px solid var(--color-primary);
    min-width: 10rem;
    padding: .5rem;

}

button {
    border-radius: 2px;
    color: var(--color-background);
    font-weight: bold;
}

/** Slider **/

.slider {
    position: relative;
}

.slider .icon {
    background: var(--color-primary);
    border-radius: 50%;
    color: var(--color-secondary);
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    top: calc(50% - .75rem);
    z-index: 10;
}

.slider .icon.arrow-left {
    left: .2em;
}

.slider .icon.arrow-right {
    right: .2em;
}

.slider .slide {
    opacity: 0;
    transition: 1s ease;
}

.slider .slide.active {
    opacity: 1;
    transition: 1s ease;
}

